// frontend/src/components/Register.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const Register = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('http://54.38.243.118:5001/auth/register', { username, password });
            setMessage('Inscription réussie ! Vous pouvez maintenant vous connecter.');
            navigate('/login');
        } catch (error) {
            setMessage('Erreur lors de l\'inscription' + error );
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>Inscription</h2>
            {message && <p>{message}</p>}
            <input type="text" placeholder="Nom d'utilisateur" value={username} onChange={(e) => setUsername(e.target.value)} />
            <input type="password" placeholder="Mot de passe" value={password} onChange={(e) => setPassword(e.target.value)} />
            <button type="submit">S'inscrire</button>
        </form>
    );
};

export default Register;
