// frontend/src/components/Login.js
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const { login } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://54.38.243.118:5001/auth/login', { username, password });
            login({ username: response.data.username }, response.data.token);
            navigate('/');
        } catch (error) {
            setError('Nom d\'utilisateur ou mot de passe incorrect');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>Connexion</h2>
            {error && <p>{error}</p>}
            <input type="text" placeholder="Nom d'utilisateur" value={username} onChange={(e) => setUsername(e.target.value)} />
            <input type="password" placeholder="Mot de passe" value={password} onChange={(e) => setPassword(e.target.value)} />
            <button type="submit">Se connecter</button>
        </form>
    );
};

export default Login;
