import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { PlusCircle, Trash2, ChevronUp, ChevronDown, Image } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import './TodoList.css';

const TodoList = () => {
    const { user, logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const [tasks, setTasks] = useState([]);
    const [newTask, setNewTask] = useState('');
    const [newTaskPriority, setNewTaskPriority] = useState('medium');
    const [newTaskImage, setNewTaskImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [taskDescription, setTaskDescription] = useState('');

    // Nouveaux états pour le modal d'image
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        if (user) {
            fetchTasks();
        } else {
            navigate('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const fetchTasks = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get('http://54.38.243.118:5001/tasks', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setTasks(response.data);
        } catch (error) {
            console.error('Erreur lors du chargement des tâches', error);
        }
    };

    const addTask = async () => {
        if (newTask.trim() !== '') {
            try {
                const reader = new FileReader();
                reader.onloadend = async () => {
                    const taskData = {
                        text: newTask,
                        priority: newTaskPriority,
                        image: reader.result || '',
                        description: '',
                    };
                    const token = localStorage.getItem('token');
                    const response = await axios.post('http://54.38.243.118:5001/tasks', taskData, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setTasks([...tasks, response.data]);
                    setNewTask('');
                    setNewTaskPriority('medium');
                    setNewTaskImage(null);
                };
                if (newTaskImage) {
                    reader.readAsDataURL(newTaskImage);
                } else {
                    reader.onloadend();
                }
            } catch (error) {
                console.error("Erreur lors de l'ajout de la tâche", error);
            }
        }
    };

    const removeTask = async (id) => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`http://54.38.243.118:5001/tasks/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setTasks(tasks.filter((task) => task._id !== id));
        } catch (error) {
            console.error('Erreur lors de la suppression de la tâche', error);
        }
    };

    const updateTask = async (id, updatedData) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.put(`http://54.38.243.118:5001/tasks/${id}`, updatedData, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setTasks(tasks.map((task) => (task._id === id ? response.data : task)));
        } catch (error) {
            console.error('Erreur lors de la mise à jour de la tâche', error);
        }
    };

    const changePriority = (id, direction) => {
        const priorities = ['low', 'medium', 'high'];
        const task = tasks.find((task) => task._id === id);
        if (task) {
            const currentIndex = priorities.indexOf(task.priority);
            const newIndex =
                direction === 'up' ? Math.min(currentIndex + 1, priorities.length - 1) : Math.max(currentIndex - 1, 0);
            const updatedTask = { ...task, priority: priorities[newIndex] };
            updateTask(id, updatedTask);
        }
    };

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setNewTaskImage(e.target.files[0]);
        }
    };

    const openModal = (task) => {
        setSelectedTask(task);
        setTaskDescription(task.description);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedTask(null);
        setTaskDescription('');
    };

    const saveDescription = () => {
        const updatedTask = { ...selectedTask, description: taskDescription };
        updateTask(selectedTask._id, updatedTask);
        closeModal();
    };

    // Fonctions pour le modal d'image
    const openImageModal = (image) => {
        setSelectedImage(image);
        setIsImageModalOpen(true);
    };

    const closeImageModal = () => {
        setIsImageModalOpen(false);
        setSelectedImage(null);
    };

    return (
        <div className="container">
            <button className="logout-button" onClick={logout}>Se déconnecter</button>
            <div className="card">
                <div className="card-header">
                    <h2>Ma Liste de Tâches</h2>
                </div>
                <div className="card-content">
                    <div className="task-inputs">
                        <input
                            type="text"
                            placeholder="Nouvelle tâche"
                            value={newTask}
                            onChange={(e) => setNewTask(e.target.value)}
                        />
                        <div className="image-upload">
                            <label htmlFor="image-upload">
                                <div className="image-upload-button">
                                    <Image size={16} />
                                    <span>{newTaskImage ? 'Image sélectionnée' : 'Ajouter une image'}</span>
                                </div>
                            </label>
                            <input
                                id="image-upload"
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                            />
                        </div>
                        <select value={newTaskPriority} onChange={(e) => setNewTaskPriority(e.target.value)}>
                            <option value="low">Basse</option>
                            <option value="medium">Moyenne</option>
                            <option value="high">Haute</option>
                        </select>
                        <button className="add-task-button" onClick={addTask}>
                            <PlusCircle size={16} /> Ajouter une tâche
                        </button>
                    </div>
                    <ul className="task-list">
                        {tasks.map((task) => (
                            <li
                                key={task._id}
                                className="task-item"
                                onClick={() => openModal(task)}
                            >
                                <div className="task-info">
                                    {task.image && (
                                        <img
                                            src={task.image}
                                            alt="Task"
                                            className="task-image"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                openImageModal(task.image);
                                            }}
                                        />
                                    )}
                                    <span>{task.text}</span>
                                </div>
                                <div className="task-actions">
                      <span className={`task-priority ${task.priority}`}>
                        {task.priority}
                      </span>
                                    <button onClick={(e) => { e.stopPropagation(); changePriority(task._id, 'up'); }}>
                                        <ChevronUp size={16} />
                                    </button>
                                    <button onClick={(e) => { e.stopPropagation(); changePriority(task._id, 'down'); }}>
                                        <ChevronDown size={16} />
                                    </button>
                                    <button onClick={(e) => { e.stopPropagation(); removeTask(task._id); }}>
                                        <Trash2 size={16} />
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {/* Modal pour la description de la tâche */}
            {isModalOpen && selectedTask && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                            <h3>{selectedTask.text}</h3>
                        </div>
                        <textarea
                            placeholder="Ajoutez une description..."
                            value={taskDescription}
                            onChange={(e) => setTaskDescription(e.target.value)}
                            rows={5}
                        />
                        <div className="modal-footer">
                            <button className="modal-button" onClick={closeModal}>Annuler</button>
                            <button className="modal-button" onClick={saveDescription}>Enregistrer</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal pour afficher l'image en grand */}
            {isImageModalOpen && selectedImage && (
                <div className="modal-overlay" onClick={closeImageModal}>
                    <div className="modal" onClick={(e) => e.stopPropagation()}>
                        <img src={selectedImage} alt="Large Task" className="large-task-image" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default TodoList;
